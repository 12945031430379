



  



  

  

  
  
  /*---------------------------------------------*/
  input {
      outline: none;
      border: none;
  }
 
  
 
  
  input:focus::-webkit-input-placeholder { color:transparent; }
  input:focus:-moz-placeholder { color:transparent; }
  input:focus::-moz-placeholder { color:transparent; }
  input:focus:-ms-input-placeholder { color:transparent; }
  
 
  
  input::-webkit-input-placeholder { color: #999999;}
  input:-moz-placeholder { color: #999999;}
  input::-moz-placeholder { color: #999999;}
  input:-ms-input-placeholder { color: #999999;}

  

  
  /*---------------------------------------------*/
  button {
      outline: none !important;
      border: none;
      background: transparent;
  }
  
  button:hover {
      cursor: pointer;
  }
  


  .txt1 {
   
    font-size: 13px;
    color: #ffffff;
    line-height: 1.4;
    text-transform: uppercase;
  }
  
  .txt2 {
  
    font-size: 13px;
    color: #ffffff;
    line-height: 1.4;
  }
  

  

  
  .limiter {
    width: 100%;
    margin: 0 auto;
  }
  
  .container-login100 {
    width: 100%;  
    min-height: 100vh;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 15px;
    
    background:url('../../../../assets/images/footer-bg.png');
    background-size: cover;
  }
  
  
  .wrap-login100 {
    width: 560px;
    background:#ffffff30;;
    border-radius: 10px;
    position: relative;
  }
  
  

  
  .login100-form {
    width: 100%;
    padding: 3rem;
}
  
  .login100-form-title {
    font-size: 30px;
    color: #ffffff;
    line-height: 1.2;
    text-transform: uppercase;
    text-align: left;
    margin: 0px 0 25px 0;

    width: 100%;
    display: block;
  }
  
  
  
  /*------------------------------------------------------------------
  [ Input ]*/
  
  .wrap-input100 {
    width: 100%;
    position: relative;
    background-color: #fff;
    border: 1px solid #e6e6e6;
    border-radius: 2px;
    margin: 15px 0px;
  }
  
  
  /*---------------------------------------------*/
  .input100 {
   
    line-height: 1.2;
    font-size: 18px;
  
    display: block;
    width: 100%;
    background: transparent;
    height: 55px;
    padding: 0 25px 0 25px;
  }
  /*------------------------------------------------------------------
  [ Focus Input ]*/
  
  .focus-input100 {
    position: absolute;
    display: block;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    top: -1px;
    left: -1px;
    pointer-events: none;
    border: 1px solid #57b846;
    border-radius: 3px;
  
    visibility: hidden;
    opacity: 0;
  
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
  
    -webkit-transform: scaleX(1.1) scaleY(1.3);
    -moz-transform: scaleX(1.1) scaleY(1.3);
    -ms-transform: scaleX(1.1) scaleY(1.3);
    -o-transform: scaleX(1.1) scaleY(1.3);
    transform: scaleX(1.1) scaleY(1.3);
  }
  
  .input100:focus + .focus-input100 {
    visibility: visible;
    opacity: 1;
  
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  
  .eff-focus-selection {
    visibility: visible;
    opacity: 1;
  
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  
  /*---------------------------------------------*/
  .btn-show-pass {
    font-size: 15px;
    color: #999999;
  
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    position: absolute;
    height: 100%;
    top: 0;
    right: 12px;
    padding: 0 5px;
    cursor: pointer;
    -webkit-transition: background 0.4s;
    -o-transition: background 0.4s;
    -moz-transition: background 0.4s;
    transition: background 0.4s;
  }
  
  .btn-show-pass:hover {
    color: #57b846;
  }
  
  .btn-show-pass.active {
    color: #57b846;
  }
  
  /*==================================================================
  [ Restyle Checkbox ]*/
  
  .input-checkbox100 {
    display: none;
  }
  
  .label-checkbox100 {
    font-size: 13px;
    color: #999999;
    line-height: 1.4;
  
    display: block;
    position: relative;
    padding-left: 26px;
    cursor: pointer;
  }
  
  .label-checkbox100::before {
    content: "\f00c";
    font-family: FontAwesome;
    font-size: 13px;
    color: transparent;
  
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 18px;
    height: 18px;
    border-radius: 2px;
    background: #fff;
    border: 1px solid #e6e6e6;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  
  .input-checkbox100:checked + .label-checkbox100::before {
    color: #57b846;
  }
  
  
  /*------------------------------------------------------------------
  [ Button ]*/
  .container-login100-form-btn {
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
  }
  
  .login100-form-btn {
    font-family: Raleway-Bold;
    font-size: 18px;
    color: #fff;
    line-height: 1.2;
  
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    width: 100%;
    height: 55px;
    background-color: #333333;
    border-radius: 2px;
  
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
  }
  
  /* .login100-form-btn:hover {
    background-color: #57b846;
  } */
  
  
  /*------------------------------------------------------------------
  [ Alert validate ]*/
  
  .validate-input {
    position: relative;
  }
  
  .alert-validate .btn-show-pass {
    visibility: hidden;
  }
  
  .alert-validate::before {
    content: attr(data-validate);
    position: absolute;
    max-width: 70%;
    background-color: #fff;
    border: 1px solid #c80000;
    border-radius: 3px;
    padding: 4px 25px 5px 10px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 12px;
    pointer-events: none;
  
    color: #c80000;
    font-size: 14px;
    line-height: 1.4;
    text-align: left;
  
    visibility: hidden;
    opacity: 0;
  
    -webkit-transition: opacity 0.4s;
    -o-transition: opacity 0.4s;
    -moz-transition: opacity 0.4s;
    transition: opacity 0.4s;
  }
  
  .alert-validate::after {
    content: "\f12a";
    font-family: FontAwesome;
    display: block;
    position: absolute;
    color: #c80000;
    font-size: 18px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 18px;
  }
  
  .alert-validate:hover:before {
    visibility: visible;
    opacity: 1;
  }
  
  @media (max-width: 992px) {
    .alert-validate::before {
      visibility: visible;
      opacity: 1;
    }
  }
  
  
  /*//////////////////////////////////////////////////////////////////
  [ Responsive ]*/
  
  @media (max-width: 576px) {
    .wrap-login100 {
      padding-left: 15px;
      padding-right: 15px;
    }
  }



  /*[ FONT SIZE ]
///////////////////////////////////////////////////////////
*/
