#banner-wrap{
	background-size: cover !important;
	background-position: center top !important;
	background-repeat: no-repeat !important;
	height: 72vh;
	text-align: center;
    position: relative;
    background-image: url('../../../assets/images/about-banner.png');
}
.banner-content{
	padding-top: 20%;
}
.banner-content h1{
	font-family: 'NexaBold';
	color: #fff;
	font-size: 60px;
	line-height: 1;
	margin-bottom: 15px;
}
ul.breadcrumb {
    list-style: none;
}
ul.breadcrumb li {
	display: inline;
	font-size: 18px;
	color: #fff;
}
ul.breadcrumb li+li:before {
    padding: 8px;
    color: #fff;
    content: "\203A";
}
ul.breadcrumb li a {
	font-family: 'Philosopher-Regular';
    color: #09a292;
	text-decoration: none;
	font-size: 18px;
}
ul.breadcrumb li a:hover {
	color: #09a292;
    text-decoration: underline;
}
.innerpage .simplestep-content{
	text-align: center;
}
.innerpage #simplestep{
	background: #fff url('../../../assets/images/aboutsectionone-bg.jpg') no-repeat center top;
    background-size: 100% auto;
	padding: 40px 0;
	margin-top: -20px;
}
.innerpage .simplestep-content .simplestep-heading h6{
	background-position: center top;
	padding-top: 60px;
}
.innerpage .simplestep-heading h3::before{
	content: "";
    border: 0.5px dotted #3d3d3d;
    display: block;
    width: 50%;
    margin: 0 auto 5px;
}
.innerpage  .simplestep-heading h3{
	color: #2e6d99;
	font-size: 24px;
	line-height: 34px;
	font-family: 'NexaLight';
	font-weight: normal;
	margin-bottom: 25px;
}
.innerpage  .simplestep-heading h3::after{
	content: "";
    border: 0.5px dotted #3d3d3d;
    display: block;
    width: 50%;
	margin: 5px auto 0;
}
.innerpage .simplestep-content .simplestep-heading h2{
	margin-bottom: 30px;
}
.innerpage .simplestep-heading > p{
	font-family: 'NexaLight';
	margin-bottom: 40px;
}
.innerpage #why-alkitaab{
	padding: 0 0 50px 0;
	background: none;
}
.innerpage #why-alkitaab .kitaab-box{
	width: 30%;
	padding: 0;
	box-shadow:0px 0px 20px 3px rgba(45, 45, 45, 0.1);
}
 .kitaab-box .section-img img{
	display: block;
	width: 100%;
	height: auto;
}
.innerpage .kitaab-box .section-content{
	padding: 40px 25px;
	background: #fff url('../../../assets/images/section-bg.jpg') no-repeat center bottom;
	background-size: 100% auto;
}
.innerpage #why-alkitaab .kitaab-box:hover{
	background-image: -moz-linear-gradient( 180deg, rgb(46,108,150) 0%, rgb(6,165,143) 100%);
	background-image: -webkit-linear-gradient( 180deg, rgb(46,108,150) 0%, rgb(6,165,143) 100%);
	background-image: -ms-linear-gradient( 180deg, rgb(46,108,150) 0%, rgb(6,165,143) 100%);
	box-shadow:0px 0px 10px 3px rgba(45, 45, 45, 0.50);
}
.innerpage #why-alkitaab .kitaab-box h3{
	margin-bottom: 30px;
	padding-bottom: 30px;
}
.innerpage #why-alkitaab .kitaab-box p{
	font-size: 18px;
	line-height: 24px;
	margin: 0;
}
.innerpage #why-alkitaab .kitaab-box:hover .section-content{
	background: none;
}
.innerpage #registertutor{
	background: url('../../../assets/images/community-cen-bg.jpg') no-repeat center top;
	background-size: cover;
	padding: 65px 0;
	text-align: left;
}
.innerpage #registertutor h2{
	background-position: left top;
	text-align: left;
	line-height: 65px;
	margin-bottom: 40px;
	font-size: 50px;
}
.innerpage .community-content{
	background: url('../../../assets/images/quetos.png') no-repeat;
	background-size: 44px 35px;
	padding-left: 70px;
}
.innerpage .community-content p{
	font-family: 'NexaLight';
	color: #fff;
	font-size: 21px;
	line-height: 30px;
	margin-bottom: 15px;
	padding-right: 29%;
}
.innerpage .community-content span{
	font-family: 'NexaLight';
	color: #06a191;
	font-size: 18px;
}
.innerpage .boardscholors .simplestep-heading h3::before,
.innerpage .boardscholors .simplestep-heading h3::after{
	width: 85%;
	margin: 0 auto 10px;
}
.innerpage .boardscholors .simplestep-heading h3::after{
	margin: 10px auto 0;
}
.innerpage .boardscholors .simplestep-heading h3{
	font-style: italic;
	color: #0ea393;
	font-family: 'NexaBold';
}
.innerpage .boardscholors .simplestep-heading h3 span{
	display: block;
	color: #afafaf;
	font-size: 14px;
	font-style: normal !important;
	text-transform: uppercase;
}
.innerpage .scholors-wrap{
	padding: 30px 0;
}
.innerpage .scholors-wrap .scholors{
	display: inline-block;
    width: 27%;
    background: #fff;
    text-align: center;
    margin-right: 25px;
    vertical-align: top;
    min-height: 273px;
}
.innerpage .scholors-wrap .mr-0{
	margin-right: 0;
}
.innerpage .scholors-wrap .scholor-img img{
	display: block;
	width: 100%;
	height: auto;
}
.innerpage .scholors-wrap .scholor-content{
	padding: 30px 5px;
}
.innerpage .scholors-wrap .scholor-content span{
	font-size: 15px;
	color: #333333;
	display: block;
	margin-bottom: 15px;
	font-family: 'NexaLight';
}
.innerpage .scholors-wrap .scholor-content h4{
	font-size: 27px;
	color: #222222;
	font-family: 'NexaBold';
	margin-bottom: 15px;
}
.innerpage .scholors-wrap .scholor-content ul{
	padding-bottom:20px;
	text-align: center;
}
.innerpage .scholors-wrap .scholor-content li{
	display: inline-block;
	margin-right: 15px;
	vertical-align: middle;
}
.innerpage .scholors-wrap li.facebook a{
	display: block;
	background: url('../../../assets/images/fb-icon.png') no-repeat;
	background-size: 100% auto;
	width: 14px;
	height: 15px;
}
.innerpage .scholors-wrap li.twitter a{
	display: block;
	background: url('../../../assets/images/twitter-icon.png') no-repeat;
	background-size: 100% auto;
	width: 16px;
	height: 15px;
}
.innerpage .scholors-wrap li.linkedin a{
	display: block;
	background: url('../../../assets/images/linkedin-icon.png') no-repeat;
	background-size: 100% auto;
	width: 14px;
	height: 15px;
}
.innerpage .scholors-wrap li.youtube a{
	display: block;
	background: url('../../../assets/images/youtube-icon.png') no-repeat;
	background-size: 100% auto;
	width: 18px;
	height: 15px;
}
.innerpage .scholors-wrap li:hover a{
	opacity: 0.7;
}
.innerpage .scholor-content p{
	font-size: 15px;
	line-height: 26px;
	color: #333333;
	margin: 0;
	font-family: 'NexaLight';
}
.innerpage .scholors-wrap .default-btn{
	margin-top: 30px;
}