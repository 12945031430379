.call-page{
    min-height: 100vh;
    background-image: url('../assets/images/c.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
}
.startclass-btn{
    position: absolute;
    bottom: 10;
    left:50%
}