.rating-screen{
    min-height: 100vh;
    background: #ececec;
    display: flex;
    justify-content: center;
    align-items: center;
}

.site-card-border-less-wrapper {
    padding: 30px;
    /* background: #ececec; */
  }

  .rating-submit{
    background: green;
    color: white;
    padding: 10px 45px;
    border-radius: 2px;
}