.card-container {
    position: relative;
    max-width: 1920px;
}
/* .card-container p {
    margin: 0;
  }
  .card-container > .ant-tabs-card .ant-tabs-content {
    height: 120px;
    margin-top: -16px;
  }
  .card-container > .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
    background: #fff;
    padding: 16px;
  }
  .card-container > .ant-tabs-card > .ant-tabs-nav::before {
    display: none;
  }
  .card-container > .ant-tabs-card .ant-tabs-tab,
  [data-theme='compact'] .card-container > .ant-tabs-card .ant-tabs-tab {
    border-color: transparent;
    background: transparent;
  }
  .card-container > .ant-tabs-card .ant-tabs-tab-active,
  [data-theme='compact'] .card-container > .ant-tabs-card .ant-tabs-tab-active {
    border-color: #fff;
    background: #fff;
  }
  #components-tabs-demo-card-top .code-box-demo {
    background: #f5f5f5;
    overflow: hidden;
    padding: 24px;
  }
  [data-theme='compact'] .card-container > .ant-tabs-card .ant-tabs-content {
    height: 120px;
    margin-top: -8px;
  }
  [data-theme='dark'] .card-container > .ant-tabs-card .ant-tabs-tab {
    border-color: transparent;
    background: transparent;
  }
  [data-theme='dark'] #components-tabs-demo-card-top .code-box-demo {
    background: #000;
  }
  [data-theme='dark'] .card-container > .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
    background: #141414;
  }
  [data-theme='dark'] .card-container > .ant-tabs-card .ant-tabs-tab-active {
    border-color: #141414;
    background: #141414;
  } */

  /* .custom-tabnav .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
      flex-direction: row-reverse;
  } */
  .custom-tabnav > .ant-tabs-nav .ant-tabs-nav-wrap {
      flex-direction: row-reverse;
  }
  .custom-tabnav > .ant-tabs-nav::before{
      border-bottom: 0;
  }
  .custom-tabnav > .ant-tabs-nav{
    margin: 0;
  }
  .listing{
    background:#f3f3f3;
    min-height: 80vh;
  }
  .avatar {
    vertical-align: middle;
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }
  .content div {
    color: #3698e4;
    font-size: 20px;
  }
  .info div{
    font-size: 15px;
    line-height: 26px;
  }
  .content small {
    color: gray;
    display: block;
    font-weight: 900;
  }
  .content strong {
    color: black;
  }
  .end-btns > button{
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 900;
    margin: 35px 5px 0;
    width: 88px;
    padding: 5px 0 0;
  }
  .edit-btn{
      background: #02a28e ;
      color:white;
      border: 1px solid  #02a28e ;
      box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  }
  .detail-text{
    color: gray !important;
    font-size: 12px !important;
    margin-top: 10px;
    display: inline-block;
  }
  .detail-text:hover{
    color: #62c78b  !important;
    font-size: 12px !important;
    margin-top: 10px;
    cursor: pointer;
    text-decoration: underline;

  }
  .back-btn{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    cursor: pointer;
    background: #02a28e;
    padding: 15px 15px 10px 13px;
    color: white;
    margin-left: 2px;
  }
  .illustrator {
    display:flex ;
    align-items:center;
    flex-direction: column;
    justify-content: center;
    
  }
  .not-fount-text{
    color:gray;
    font-size: 15px;
  }
  .level-box{
    padding: 18px 20px;
    
    }
    .radio-label{
      color:black !important;
  
    }
    .level-txt{
      padding-top: 5px;
     
    }
    .level-text{
      font-size:12px;
      margin-right: 10px;
    }
    .search-div{
      position: absolute;
      margin-top: -50px;
      display: flex;
    }
    .search-div input:focus{
      outline: none;
      border: none;
      box-shadow: none;
    }
    .search-div button{
      background: #02a28e;
      width: 160px;
      height: 48px;
      color: white;
      font-family: 'NexaBold';
    }
    .search-div input {
      width: 360px;
    }
    .approve{
      font-size: 14px !important;
      color: green !important;
    }
    .not-approve{
      font-size: 14px !important;
      color: gray !important;
    }
    .blocked{
      font-size: 14px !important;
      color: red !important;
    }