.card-container {
    position: relative;
    max-width: 1920px;
}
  .custom-tabnav > .ant-tabs-nav .ant-tabs-nav-wrap {
      flex-direction: row-reverse;
  }
  .custom-tabnav > .ant-tabs-nav::before{
      border-bottom: 0;
  }
  .custom-tabnav > .ant-tabs-nav{
    margin: 0;
  }
  .listing{
    background:#f3f3f3;
    min-height: 100vh;
  }
  .avatar {
    vertical-align: middle;
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }
  .content div {
    color: #3698e4;
    font-size: 20px;
  }
  .content small {
    color: gray;
    display: block;
    font-weight: 900;
  }
  .content strong {
    color: black;
  }
  .end-btns > button{
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 900;
    margin: 35px 5px 0;
    width: 88px;
    padding: 5px 0 0;
  }
  .edit-btn{
      background: #02a28e ;
      color:white;
      border: 1px solid  #02a28e ;
      box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  }
  .search-div{
    position: absolute;
    margin-top: -50px;
    display: flex;
  }
  .search-div input:focus{
    outline: none;
    border: none;
    box-shadow: none;
  }
  .search-div button{
    background: #02a28e;
    width: 160px;
    height: 48px;
    color: white;
    font-family: 'NexaBold';
  }
  .search-div input {
    width: 360px;
  }
  .approve{
    font-size: 14px !important;
    color: green !important;
  }
  .not-approve{
    font-size: 14px !important;
    color: gray !important;
  }
  .blocked{
    font-size: 14px !important;
    color: red !important;
  }